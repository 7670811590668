<template>
  <div>
    <rxNavBar v-if="androidFlg == false" title="试岗模板预览" androidOrIOSFlag="true"></rxNavBar>
    <rxNavBar v-else title="试岗模板预览"></rxNavBar>
    <div id="planInfoPreview"></div>
  </div>
</template>

<script>
import {
  Button,
  Divider,
  DropdownItem,
  DropdownMenu, Image as VanImage,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search, Tab, Tabs,
  uploader
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {checkAndroid, checkIOS, getStaffId} from "../../../libs/rongxunUtil";
import {queryProbationPlan} from "../../../getData/getData";

export default {
  name: "previewTempate",
  components: {
    [Button.name]:Button,
    [Search.name]:Search,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Divider.name]:Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [uploader .name]:uploader,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [VanImage.name]: VanImage,
  },
  data(){
    return{
      androidFlg:false,
      trialplanrecordList:[],
    }
  },
  mounted() {
    this.checkPhoneorMobel()
    this.initProbationPlanDetail()
  },
  methods:{
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    initProbationPlanDetail(){
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.probationPlan_id = this.$route.query.id
      this.trialplanrecordList = []
      queryProbationPlan(initData).then(function (response) {

        let content = response.data.data.content  //主文本
        let planDetailList = response.data.data.planDetailList  //替换码
        for(let i in planDetailList){
          let charToReplace = planDetailList[i].substitution; // 要被替换的字符
          let charToReplaceStr = (planDetailList[i].substitution).substring(2, planDetailList[i].substitution.length - 2); // 要被替换的绑定键
          let ownContent = planDetailList[i].content!=undefined&&planDetailList[i].content!==''?planDetailList[i].content:'';  //作答的填写
          let replacementHTML = `<span class="divSpanBox">【${ownContent}】</span>`
          content = content.replace(charToReplace,replacementHTML)
        }
        let newParagraph = document.createElement("p")  //创建内容元素节点
        newParagraph.insertAdjacentHTML("beforeend",content);  //放HTML  //在元素里最后一个节点后 插入一个html
        let container = document.getElementById("planInfoPreview");
        container.appendChild(newParagraph);

        //赋值class，写样式
        for(let j in document.getElementsByTagName("p")){
          if(typeof document.getElementsByTagName("p")[j]=='object'){
            document.getElementsByTagName("p")[j].className = 'divBox'
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
*{
  margin: 0px;
  padding: 0px;
}
#planInfoPreview{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.2rem;
  background-color: white;
  border-radius: 0.5rem;
}
.learningAttachmentsTitle {
  padding: 5px 0px;
  color: rgb(244, 99, 76);
  margin: 25px 0.3rem 0rem 0.4rem;
  font-weight: 700;
  font-size: 12px;
}
.learningAttachments{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.5rem;
}
::v-deep .divBox{
  font-size: 16px;
}
::v-deep .divSpanBox{
  color: blue;
  text-decoration: underline;
}
::v-deep img{
  width: 100%;
  height: auto;
}
</style>